export const jiXiaoDengJi = [
  {
    path: '/jxdj',
    component: () => import('@/views/jiXiaoDengJi/jiXiaoDengJi.vue')
  },
  {
    path: '/jxdj/sqbj',
    component: () => import('@/views/jiXiaoDengJi/shenQingBuJi.vue')
  },
  {
    path: '/jxdj/bjsp',
    component: () => import('@/views/jiXiaoDengJi/jiXiaoBuJiShenPi.vue')
  },
  {
    path: '/jxdj/czffspjj',
    component: () => import('@/views/jiXiaoDengJi/caoZuoFangFaShiPingJiangJie.vue')
  }
]
