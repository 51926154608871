export const articles = [
  {
    path: '/articleDetails',
    component: () => import('@/views/articles/articleDetails.vue')
  },
  // {
  //   path: '/createArticle',
  //   component: () => import('@/views/articles/createArticle.vue')
  // },
  // {
  //   path: '/editArticle',
  //   component: () => import('@/views/articles/editArticle.vue')
  // },
  {
    path: '/articlesList',
    component: () => import('@/views/articles/articlesList.vue')
  }
]
