// 从地址提取户籍
import dayjs from 'dayjs'

export const addressToXianQu = (address) => {
  if (address.includes('红海湾')) {
    return '红海湾'
  }
  if (address.includes('市辖区')) {
    return '城区'
  }
  if (address.includes('城区')) {
    return '城区'
  }
  if (address.includes('红草镇')) {
    return '城区'
  }
  if (address.includes('陆丰')) {
    return '陆丰'
  }
  if (address.includes('陆河')) {
    return '陆河'
  }
  if (address.includes('海丰')) {
    return '海丰'
  }
  if (address.includes('城东镇')) {
    return '海丰'
  }
  if (address.includes('附城镇')) {
    return '海丰'
  }
  if (address.includes('海城镇')) {
    return '海丰'
  }
}

export class GetIDInfo {
  // 提取身份证号信息：出生日期、性别、年龄
  constructor (id) {
    this.id = id
    this.birthYear = parseInt(id.slice(6, 10))
    this.birthMonth = parseInt(id.slice(10, 12))
    this.birthDay = parseInt(id.slice(12, 14))
  }

  checkId () {
    const checkList = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2, 0] // 校验列表
    // 尾数校验码
    const modAndLastStrDict = {
      0: '1',
      1: '0',
      2: 'X',
      3: '9',
      4: '8',
      5: '7',
      6: '6',
      7: '5',
      8: '4',
      9: '3',
      10: '2'
    }
    const sumList = []
    for (let k = 0; k < 18; k++) {
      if (this.id[k] === 'X' && k === 17) {
        sumList.push(0)
        break
      }
      sumList.push(parseInt(this.id[k] * checkList[k]))
    }
    const sum = sumList.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    if (modAndLastStrDict[(sum % 11)] !== this.id[17]) {
      return '身份证错误'
    } else {
      return true
    }
  }

  getBirthday () {
    if (this.checkId() === '身份证错误') {
      return '身份证错误'
    }
    const birthday = `${this.birthYear}-${this.birthMonth}-${this.birthDay}`
    return birthday
  }

  getGender () {
    if (this.checkId() === '身份证错误') {
      return '身份证错误'
    }
    const num = parseInt(this.id.slice(16, 17))
    if (num % 2 === 0) {
      return '女'
    } else {
      return '男'
    }
  }

  getAge (nowDate = undefined) {
    if (this.checkId() === '身份证错误') {
      return '身份证错误'
    }
    let year, month, day
    if (nowDate) {
      if (typeof nowDate === 'string') {
        nowDate = dayjs(nowDate)
        year = nowDate.year()
        month = nowDate.month() + 1 // 因为dayjs的月是0-11
        day = nowDate.day()
      }
    } else {
      const now = dayjs()
      year = now.year()
      month = now.month()
      day = now.day()
    }
    if (year === this.birthYear) {
      return 0
    } else {
      if (this.birthMonth > month || (this.birthMonth === month && this.birthDay > day)) {
        return year - this.birthYear - 1
      } else {
        return year - this.birthYear
      }
    }
  }

  getLongAge (nowDate = undefined) {
    console.log(nowDate)
    if (this.checkId() === '身份证错误') {
      return '身份证错误'
    }
    let year, month//, day
    if (nowDate) {
      if (typeof nowDate === 'string') {
        nowDate = dayjs(nowDate)
        year = nowDate.year()
        month = nowDate.month() + 1 // 因为dayjs的月是0-11
        // day = nowDate.day()
      }
    } else {
      const now = dayjs()
      year = now.year()
      month = now.month() + 1 // 因为dayjs的月是0-11
      // day = now.day()
    }
    if (year - this.birthYear > 7) {
      if (month - this.birthMonth >= 0) {
        return `${year - this.birthYear}岁`
      } else {
        return `${year - this.birthYear - 1}岁`
      }
    } else if (year - this.birthYear === 7) {
      if (month - this.birthMonth >= 0) {
        return '7岁'
      } else {
        return `${year - this.birthYear - 1}岁${month - this.birthMonth + 12}个月`
      }
    } else {
      if (month - this.birthMonth > 0) {
        return `${year - this.birthYear}岁${month - this.birthMonth}个月`
      } else if (month - this.birthMonth === 0) {
        return `${year - this.birthYear}岁`
      } else {
        return `${year - this.birthYear - 1}岁${month - this.birthMonth + 12}个月`
      }
    }
  }

  getLongAgeForDay (nowDate = undefined) {
    if (this.checkId() === '身份证错误') {
      return '身份证错误'
    }
    let year, month, date
    if (nowDate) {
      if (typeof nowDate === 'string') {
        nowDate = dayjs(nowDate)
        year = nowDate.year()
        month = nowDate.month() + 1 // 因为dayjs的月是0-11
        date = nowDate.date()
      }
    } else {
      const now = dayjs()
      year = now.year()
      month = now.month() + 1 // 因为dayjs的月是0-11
      date = now.date()
    }
    if (year - this.birthYear > 7) {
      if (month - this.birthMonth > 0) {
        return `${year - this.birthYear}岁`
      } else if (month - this.birthMonth === 0) {
        if (date - this.birthDay >= 0) {
          return `${year - this.birthYear}岁`
        } else {
          return `${year - this.birthYear - 1}岁`
        }
      } else {
        return `${year - this.birthYear - 1}岁`
      }
    } else if (year - this.birthYear === 7) {
      if (month - this.birthMonth > 0) {
        return '7岁'
      } else if (month - this.birthMonth === 0) {
        if (date - this.birthDay >= 0) {
          return '7岁'
        } else {
          return `${year - this.birthYear - 1}岁${month - this.birthMonth + 12 - 1}个月`
        }
      } else {
        if (date - this.birthDay >= 0) {
          return `${year - this.birthYear - 1}岁${month - this.birthMonth + 12}个月`
        } else {
          if (month - this.birthMonth + 12 - 1 === 0) {
            return `${year - this.birthYear - 1}岁`
          } else {
            return `${year - this.birthYear - 1}岁${month - this.birthMonth + 12 - 1}个月`
          }
        }
      }
    } else {
      if (month - this.birthMonth > 0) {
        if (date - this.birthDay >= 0) {
          return `${year - this.birthYear}岁${month - this.birthMonth}个月`
        } else if (date - this.birthDay < 0) {
          if (month - this.birthMonth === 1) {
            return `${year - this.birthYear}岁`
          } else {
            return `${year - this.birthYear}岁${month - this.birthMonth - 1}个月`
          }
        }
      } else if (month - this.birthMonth === 0) {
        if (date - this.birthDay >= 0) {
          return `${year - this.birthYear}岁`
        } else {
          return `${year - this.birthYear - 1}岁${month - this.birthMonth + 12 - 1}个月`
        }
      } else {
        if (date - this.birthDay >= 0) {
          return `${year - this.birthYear - 1}岁${month - this.birthMonth + 12}个月`
        } else {
          if (month - this.birthMonth + 12 - 1 === 0) {
            return `${year - this.birthYear - 1}岁`
          } else {
            return `${year - this.birthYear - 1}岁${month - this.birthMonth + 12 - 1}个月`
          }
        }
      }
    }
  }
}

// 随机字符串
export const generateSecureRandomString = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  const randomValues = new Uint32Array(length)

  window.crypto.getRandomValues(randomValues)

  let result = ''
  for (let i = 0; i < length; i++) {
    result += characters.charAt(randomValues[i] % charactersLength)
  }

  return result
}

export const compareAndChangeObjProperties = (obj, key, givenValue) => {
  if (typeof givenValue === 'number') {
    if (obj[key] !== givenValue.toString()) {
      obj[key] = givenValue.toString()
      obj.更改标志 = true
      console.log(key, obj[key], typeof obj[key], givenValue, typeof givenValue)
    }
  } else {
    if (obj[key] !== givenValue) {
      obj[key] = givenValue
      obj.更改标志 = true
      console.log(key, obj[key], typeof obj[key], givenValue, typeof givenValue)
    }
  }
  // if (obj[key] !== givenValue) {
  //   obj[key] = givenValue
  //   obj.更改标志 = true
  // }
  return obj
}


export const getUid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0,
      v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}
