import jwtDecode from 'jwt-decode'

const ACCESSKEY = 'hflqyy-kfk-access-token'
export const setAccessToken = (newToken) => localStorage.setItem(ACCESSKEY, newToken)
export const getAccessToken = () => localStorage.getItem(ACCESSKEY)
export const delAccessToken = () => localStorage.removeItem(ACCESSKEY)

const REFRESHKEY = 'hflqyy-kfk-refresh-token'
export const setRefreshToken = (newToken) => localStorage.setItem(REFRESHKEY, newToken)
export const getRefreshToken = () => localStorage.getItem(REFRESHKEY)
export const delRefreshToken = () => localStorage.removeItem(REFRESHKEY)
export const getUser = () => {
  const accessToken = getAccessToken()
  if (accessToken) {
    const decodedAccessToken = jwtDecode(getAccessToken())
    // const userName = decodedAccessToken.sub.name
    return decodedAccessToken.sub
  }
  return undefined
}
