export const userManage = [
  {
    path: '/userManage',
    component: () => import('@/views/userManage/userPage.vue')
  },
  {
    path: '/changePassword',
    component: () => import('@/views/userManage/changePassword.vue')
  }
]
