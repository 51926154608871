export const pingGuRoutes = [
  {
    path: '/pingGu',
    component: () => import('@/views/pingGu/pingGuIndex.vue'),
    children: [
      {
        path: 'pingGuYuYueList',
        component: () => import('@/views/pingGu/pingGuYuYueList.vue')
      },
      {
        path: 'childInfo',
        component: () => import('@/views/pingGu/childInfo.vue')
      },
      {
        path: 'createChildInfo',
        component: () => import('@/views/pingGu/createChildInfo.vue')
      },
      {
        path: 'pingGuList',
        component: () => import('@/views/pingGu/pingGuList.vue')
      },
      {
        path: 'cPep3',
        component: () => import('@/views/pingGu/cPep3.vue')
      }
    ]
  }
]
