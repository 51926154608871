import Vue from 'vue'
import Vuex from 'vuex'
import { generateSecureRandomString } from '@/utils/suUtils'
Vue.use(Vuex)

export default new Vuex.Store({
  strict: true, // 生产环境下要关闭
  state: {
    dangAnBiaoQian: [
      {
        id: generateSecureRandomString(20),
        num: '001',
        width: '5.5',
        content: ''
      }
    ],
    jianYanKeBiao: [],
    // c-pep-3
    pingGuChildInfo: undefined,
    // 病区交班本
    bingQuJiaoBan: []
  },
  getters: {
  },
  mutations: {
    closeBiaoQian (state, biaoQian) {
      state.dangAnBiaoQian = state.dangAnBiaoQian.filter(obj => obj.id !== biaoQian.id)
    },
    addBiaoQian (state) {
      state.dangAnBiaoQian.push({
        id: generateSecureRandomString(20),
        num: '',
        width: '5.5',
        content: ''
      })
    },
    changeBiaoQian (state, biaoQian) {
      if (biaoQian.field === 'num') {
        const biaoQian = state.dangAnBiaoQian.find(obj => obj.id === biaoQian.id)
        biaoQian.num = biaoQian.newVal
      } else if (biaoQian.field === 'width') {
        const biaoQian = state.dangAnBiaoQian.find(obj => obj.id === biaoQian.id)
        biaoQian.width = biaoQian.newVal
      } else if (biaoQian.field === 'content') {
        const biaoQian = state.dangAnBiaoQian.find(obj => obj.id === biaoQian.id)
        biaoQian.content = biaoQian.newVal
      }
    },
    writeJianYanKeBiao (state, list) {
      state.jianYanKeBiao = []
      list.forEach(obj => state.jianYanKeBiao.push(obj))
    },
    writePingGuChildInfo (state, infoObj) {
      state.pingGuChildInfo = infoObj
    },
    writeBingQuJiaoBan (state, jiaoBanList) {
      state.bingQuJiaoBan = jiaoBanList
    }
  },
  actions: {
  },
  modules: {
  }
})
