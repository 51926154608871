export const yiBaoRoutes = [
  {
    path: '/yiBaoIndex',
    component: () => import('@/views/yiBao/yiBaoIndex.vue'),
    children: [
      {
        path: 'dayView',
        component: () => import('@/views/yiBao/dayView.vue')
      },
      {
        path: 'monthView',
        component: () => import('@/views/yiBao/monthView.vue')
      },
      {
        path: 'outAndInView',
        component: () => import('@/views/yiBao/outAndInView.vue')
      },
      {
        path: 'ruYuanView',
        component: () => import('@/views/yiBao/ruYuanView.vue')
      },
      {
        path: 'yiZhuView',
        component: () => import('@/views/yiBao/yiZhuView.vue')
      }
    ]
  },
  {
    path: '/binQuJiaoBan',
    component: () => import('@/views/yiBao/binQuJiaoBanView.vue')
  }
]
