export const emploeeStudyRoutes = [
  {
    path: '/studyIndex',
    component: () => import('@/views/emploeeStudy/emploeeStudyIndex.vue')
  },
  {
    path: '/studyPlay',
    component: () => import('@/views/emploeeStudy/emploeeStudyPlay.vue')
  },
  {
    path: '/tongJi',
    component: () => import('@/views/emploeeStudy/emploeeStudyTongJi.vue')
  },
  {
    path: '/exportReport',
    component: () => import('@/views/emploeeStudy/emploeeStudyExportReport.vue')
  },
  {
    path: '/yiDian',
    component: () => import('@/views/emploeeStudy/emploeeStudyYiDian.vue')
  }
]
