import Vue from 'vue'
import VueRouter from 'vue-router'
import { getAccessToken } from '@/utils/storage'
import { emploeeStudyRoutes } from '@/router/emploeeStudy'
import { yiBaoRoutes } from '@/router/yiBao'
import { pingGuRoutes } from '@/router/pingGu'
import { jiXiaoDengJi } from '@/router/jiXiaoDengJi'
import { userManage } from '@/router/userManage'
import { articles } from '@/router/articles'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/indexPage.vue'),
    meta: { title: '首页' }
  },
  {
    path: '/testPage',
    component: () => import('@/views/testVue.vue')
  },
  {
    path: '/login',
    component: () => import('@/views/toLogin.vue'),
    meta: { title: '登录' }
  },
  {
    path: '/binLi',
    component: () => import('@/views/binLi.vue'),
    meta: { title: '病历存档' }
  },
  {
    path: '/wechatName',
    component: () => import('@/views/wechatNamePage.vue'),
    meta: { title: '微信儿童姓名' }
  },
  {
    path: '/kangFuDangAn',
    component: () => import('@/views/kangFuDangAnPage.vue'),
    meta: { title: '康复档案标签' }
  },
  {
    path: '/jianYanKeBiao',
    component: () => import('@/views/yiBao/jianYanKeBiao.vue'),
    meta: { title: '检验科表' }
  },
  {
    path: '/kaoQin',
    component: () => import('@/views/kaoQinPage.vue'),
    meta: { title: '考勤' }
  },
  {
    path: '/tempKangFu',
    component: () => import('@/views/tempKangFuDangAn.vue'),
    meta: { title: '档案标签【临时】' }
  },
  {
    path: '/littleTools',
    component: () => import('@/views/littleTools.vue'),
    meta: { title: '小工具' }
  },
  {
    path: '/binRenPingJun',
    component: () => import('@/views/binRenPingJun.vue'),
    meta: { title: '病区病人平均数' }
  }
]
routes.push(...emploeeStudyRoutes)
routes.push(...yiBaoRoutes)
routes.push(...pingGuRoutes)
routes.push(...jiXiaoDengJi)
routes.push(...userManage)
routes.push(...articles)

const router = new VueRouter({
  linkActiveClass: 'active',
  routes
})

router.beforeEach((to, from, next) => {
  // 执行时机：每次页面跳转都会触发该函数
  // 只要注册了全局前置守卫，路由会立即瘫痪，必须要调用next（）方法来放行
  // 参数1：to到哪里去
  // 参数2：from从哪里来
  // 参数3：next函数，决定是否放行，next（）表示放行，next（路径'）表示强制跳转到指定路由，next（false）表示不放行
  const whiteList = ['/login', '/changePassword']
  const token = getAccessToken()
  if (token) {
    document.title = to.meta.title || '老区康复'
    next()
  } else {
    if (whiteList.includes(to.path)) {
      next()
    } else {
      next('/login')
    }
  }
  next()
})

export default router
